<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-card
          color="error"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Expedientes con pendientes
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Listado de expedientes con requerimientos pendientes de los clientes
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers2"
              :items="pendientes"
              :options="options"
              height="300px"
            />
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Expedientes abiertos
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Listado de expedientes actualmente en proceso
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="abiertos"
              :options="options"
              height="300px"
            />
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-show="false"
        cols="12"
        sm="6"
        lg="6"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-file-cabinet"
          title="Expedientes abiertos"
          :value="totalExpedientesAbiertos"
        />
      </v-col>

      <v-col
        v-show="false"
        cols="12"
        sm="6"
        lg="6"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-calendar-multiselect"
          title="Próximas citas"
          value="18"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ExpedientesApi from '@/services/api/Expedientes'

  export default {
    name: 'OficialDashboard',

    data () {
      return {
        options: {
          itemsPerPage: 5,
        },
        headers: [
          {
            sortable: false,
            text: 'Cliente',
            value: 'clienteName',
          },
          {
            sortable: false,
            text: 'Procedimiento',
            value: 'procedimiento.nombre',
          },
          {
            sortable: false,
            text: 'Protocolo',
            value: 'protocolo',
          },
        ],
        abiertos: [],
        headers2: [
          {
            sortable: false,
            text: 'No. Expediente',
            value: 'expediente',
          },
          {
            sortable: false,
            text: 'Procedimiento',
            value: 'procedimiento',
          },
          {
            sortable: false,
            text: 'Protocolo',
            value: 'protocolo',
          },
        ],
        pendientes: [],
      }
    },

    computed: {
      totalExpedientesAbiertos () {
        return this.abiertos.length
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      fetchPendientes () {
        ExpedientesApi.getAllExpedientesConPendientes()
          .then(pendientes => {
            this.pendientes = pendientes
          })
          .catch(error => console.log(error))
      },
      fetchExpedientesAbiertos () {
        ExpedientesApi.getAllExpedientesAbiertos()
          .then(abiertos => {
            this.abiertos = abiertos
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchPendientes()
        this.fetchExpedientesAbiertos()
      },
    },
  }
</script>
